global.app_name = "WTR GO";

// set for local development
// global.api_url = 'https://wtr-api.amanahapp.run/';
// global.api_url_i = 'https://wtr-api.amanahapp.run';
// global.no_image = require('../../assets/img/no-image.png');

// global.api_url = "http://localhost:8000/";
// global.api_url_i = "http://localhost:8000";

// set for production
global.api_url = "http://mudik.transjawa.com/";
global.api_url_i = "http://mudik.transjawa.com";
 