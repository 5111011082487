import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { setPageLoading } from "../../../../config/redux/pageLoading/pageLoadingAction";
import {
  DeleteRestAreaData,
  GetRestAreaData,
  GetSegmentLists,
} from "../../../../config/services";
import Confirm from "../../../components/Confirm/Confirm";
import FormSearchPanel from "../../../components/Forms/FormSearchPanel";
import Pagination from "../../../components/Pagination";
import CctvDialog from "./_partials/CctvDialog";
import DialogCreate from "./_partials/DialogCreate";
import DialogDetail from "./_partials/DialogDetail";
import Item from "./_partials/Item";

const useStyles = makeStyles({
  grow: {
    flex: 1,
  },
  select: {
    minWidth: 250,
  },
  root: {
    margin: "2rem 0",
  },
});

function RestAreas() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [restAreas, setRestAreas] = useState({});
  const [search, setSearch] = useState("");
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [openCctvDialog, setOpenCctvDialog] = useState(false);
  const [currentCctv, setCurrentCctv] = useState({});
  const [currentEdit, setCurrentEdit] = useState({});
  const [currentDetails, setCurrentDetails] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentDelete, setCurrentDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [segments, setSegments] = useState([]);
  const [loadingSegments, setLoadingSegments] = useState(false);
  const [filter, setFilter] = useState(null);

  const fetchRestArea = useCallback(
    async (params) => {
      setLoading(true);
      dispatch(setPageLoading(true));
      try {
        const response = await GetRestAreaData(params);
        setRestAreas(response.data.rest_areas);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        dispatch(setPageLoading(false));
      }
    },
    [dispatch]
  );

  const handleChangePage = (event, value) => {
    fetchRestArea({
      q: search,
      page: value,
      segment_id: filter,
    });
  };

  const handleChangeLimit = (event) => {
    fetchRestArea({
      q: search,
      limit: event.target.value,
      segment_id: filter,
    });
  };

  const fetchRuasToll = async (params) => {
    setLoadingSegments(true);
    try {
      const response = await GetSegmentLists();
      setSegments(response.data.segments);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSegments(false);
    }
  };

  useEffect(() => {
    fetchRuasToll();
    fetchRestArea();
  }, [fetchRestArea]);

  const onSubmitSearch = (event) => {
    if (event.nativeEvent.key === "Enter") {
      fetchRestArea({ q: search, segment_id: filter });
    }
  };

  const handleDelete = (res) => {
    if (res) {
      setDeleteLoading(true);
      DeleteRestAreaData(currentDelete)
        .then(
          (res) => {
            fetchRestArea();
          },
          (err) => {
            console.log("Gagal menghapus data..");
          }
        )
        .finally(() => {
          setCurrentDelete(null);
          setDeleteLoading(false);
        });
    } else {
      setCurrentDelete(null);
    }
  };

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
    fetchRestArea({ segment_id: event.target.value, q: search });
  };
  return (
    <div>
      <Helmet>
        <title>Manage Rest Area</title>
      </Helmet>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item className={classes.grow}></Grid>
        <Grid item className={classes.select}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Filter Ruas Toll</InputLabel>
            <Select
              label="Ruas Toll"
              name="segment_id"
              value={filter}
              onChange={handleChangeFilter}
              placeholder="Pilih Ruas Toll"
            >
              <MenuItem value={null}>Semua Ruas</MenuItem>
              {segments.map((segment, index) => (
                <MenuItem key={index} value={segment.id}>
                  {segment.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormSearchPanel
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            onKeyPress={onSubmitSearch}
            clearSearchInput={() => {
              setSearch("");
              fetchRestArea();
            }}
          />
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => setOpenDialogCreate(true)}>
            Tambah Rest Area
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <div
          style={{
            textAlign: "center",
            minHight: 250,
            margin: "2rem 0",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={35} />
        </div>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={2}>
            {restAreas.data?.map((restArea) => {
              return (
                <Grid xs={12} md={4} item key={restArea.id}>
                  <Item
                    deleteLoading={deleteLoading}
                    restArea={restArea}
                    setCurrentDelete={setCurrentDelete}
                    setOpenDialogCreate={setOpenDialogCreate}
                    setCurrentEdit={setCurrentEdit}
                    setOpenDialogDetail={setOpenDialogDetail}
                    setCurrentDetails={setCurrentDetails}
                    setOpenCctvDialog={setOpenCctvDialog}
                    setCurrentCctv={setCurrentCctv}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Pagination
            meta={{ ...restAreas.meta, ...restAreas.links }}
            onChangePage={handleChangePage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
      {openCctvDialog && (
        <CctvDialog
          openCctvDialog={openCctvDialog}
          currentCctv={currentCctv}
          setOpenCctvDialog={setOpenCctvDialog}
          setCurrentCctv={setCurrentCctv}
        />
      )}
      {openDialogCreate && (
        <DialogCreate
          openDialogCreate={openDialogCreate}
          setOpenDialogCreate={setOpenDialogCreate}
          currentEdit={currentEdit}
          setCurrentEdit={setCurrentEdit}
          submitLoading={submitLoading}
          setSubmitLoading={setSubmitLoading}
          fetchRestArea={fetchRestArea}
          segments={segments}
        />
      )}
      {openDialogDetail && (
        <DialogDetail
          openDialogDetail={openDialogDetail}
          currentDetails={currentDetails}
          setOpenDialogDetail={setOpenDialogDetail}
          setCurrentDetails={setCurrentDetails}
        />
      )}
      {currentDelete && (
        <Confirm
          loading={deleteLoading}
          open={currentDelete}
          content="Anda yakin ingin menghapus data ?"
          onAction={(res) => handleDelete(res)}
        />
      )}
    </div>
  );
}

export default RestAreas;
