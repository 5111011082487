import { Delete, Get, Post, Put } from "./request";

// Register user
export const RegisterUser = (registration) =>
  Post("api/registration", registration);

// Update user
export const UpdateUser = (params) =>
  Post("api/users/update/" + params.get("id"), params);

// Delete user
export const DeleteUser = (params) => Delete("api/users/" + params);

// Login user
export const LoginUser = (userInfo) => Post("api/login", userInfo);

// Logout user
export const LogoutUser = () => Post("api/logout");

// Reset password
export const ResetPassword = (email) =>
  Post("api/password/send-reset-link", email);

// Do Reset password
export const DoResetPassword = (userInfo) =>
  Post("api/password/reset-password", userInfo);

// Check Authentication
export const AuthorizationCheck = () => Post("api/check/auth");

// Get Panel Menus
export const PanelMenus = () => Get("api/menus/panel");

// Get Data Users
export const GetUsersData = (params) => Get("api/users", params);

// Get Detail User
export const GetUserDetail = (id) => Get("api/users/" + id);

// Store user data
export const StoreUserData = (params) => Post("api/users/store", params);

// Get role lists
export const GetRoleLists = () => Get("api/roles/lists");

// Gerbang Tol
export const GetGatesData = (params) => Get("api/admin/gates", params);
export const GetGatesListData = (params) => Get("api/admin/gates/list", params);
export const StoreGateData = (params) => Post("api/admin/gates", params);
export const UpdateGateData = (params) =>
  Post("api/admin/gates/" + params.get("id"), params);
export const DeleteGateData = (params) => Delete("api/admin/gates/" + params);

// Posts News
export const GetPostsData = (params) => Get("api/admin/posts", params);
export const StorePostsData = (params) => Post("api/admin/posts", params);
export const UpdatePostsData = (params) =>
  Post("api/admin/posts/" + params.get("id"), params);
export const DeletePostsData = (id) => Delete("api/admin/posts/" + id);

// Streams CCTV
export const GetStreamsData = (params) => Get("api/admin/streams", params);
export const StoreStreamData = (params) => Post("api/admin/streams", params);
export const UpdateStreamData = (params) =>
  Put("api/admin/streams/" + params.id, params);
export const ChangeStatusStreamData = (params) =>
  Post("api/admin/streams/" + params.id + "/status", params);
export const DeleteStreamData = (id) => Delete("api/admin/streams/" + id);

// Segments
export const GetSegments = (params) => Get("api/admin/segments", params);
export const StoreSegmentData = (params) => Post("api/admin/segments", params);
export const UpdateSegmentData = (params, id) =>
  Put(`api/admin/segments/${id}`, params);
export const DeleteSegmentData = (id) => Delete(`api/admin/segments/${id}`);
export const GetSegmentLists = () => Get("api/admin/segments/lists");

// Rest Areas
export const GetRestAreaData = (params) => Get("api/admin/rest-areas", params);
export const StoreRestAreaData = (params) =>
  Post("api/admin/rest-areas", params);
export const UpdateRestAreaData = (params) =>
  Post("api/admin/rest-areas/" + params.get("id"), params);
export const DeleteRestAreaData = (id) => Delete("api/admin/rest-areas/" + id);

// Sliders
export const GetSlidersData = (params) => Get("api/admin/sliders", params);
export const StoreSlidersData = (params) => Post("api/admin/sliders", params);
export const UpdateSlidersData = (params) =>
  Post("api/admin/sliders/" + params.get("id"), params);
export const DeleteSlidersData = (id) => Delete("api/admin/sliders/" + id);

// Hotels
export const GetHotelsData = (params) => Get("api/admin/hotels", params);
export const StoreHotelsData = (params) => Post("api/admin/hotels", params);
export const UpdateHotelsData = (params) =>
  Post("api/admin/hotels/" + params.get("id"), params);
export const DeleteHotelsData = (id) => Delete("api/admin/hotels/" + id);

// Place Recomendations
export const GetPlaceRecomendationsData = (params) =>
  Get("api/admin/place-recomendations", params);
export const StorePlaceRecomendationsData = (params) =>
  Post("api/admin/place-recomendations", params);
export const UpdatePlaceRecomendationsData = (params) =>
  Post("api/admin/place-recomendations/" + params.get("id"), params);
export const DeletePlaceRecomendationsData = (id) =>
  Delete("api/admin/place-recomendations/" + id);

// Place Recomendations
export const GetFeulssData = (params) => Get("api/admin/feuls", params);
export const StoreFeulssData = (params) => Post("api/admin/feuls", params);
export const UpdateFeulssData = (params) =>
  Post("api/admin/feuls/" + params.get("id"), params);
export const DeleteFeulssData = (id) => Delete("api/admin/feuls/" + id);
export const ChangeStatusFeulsData = (params) =>
  Post("api/admin/feuls/" + params.id + "/status", params);

export const GetLogData = (params) => Get("api/admin/log", params);
export const GetLogList = (params) => Get("api/admin/visitor-log", params);

// Toll Rate
export const GetTollRates = (params) => Get("api/admin/toll-rates", params);
export const StoreTollRateData = (params) =>
  Post("api/admin/toll-rates", params);
export const UpdateTollRateData = (params, id) =>
  Put(`api/admin/toll-rates/${id}`, params);
export const DeleteTollRateData = (id) => Delete(`api/admin/toll-rates/${id}`);

// Tracks
export const GetTracks = (params) => Get("api/admin/segments/tracks", params);
export const StoreTrackData = (params) =>
  Post("api/admin/segments/tracks", params);
export const UpdateTrackData = (params, id) =>
  Put(`api/admin/segments/tracks/${id}`, params);
export const DeleteTrackData = (id) =>
  Delete(`api/admin/segments/tracks/${id}`);

// SegmentGates
export const GetSegmentGates = (params) =>
  Get("api/admin/segments/gates", params);
export const GetSegmentGatesList = (params) =>
  Get("api/admin/segments/gates/list", params);
export const StoreSegmentGateData = (params) =>
  Post("api/admin/segments/gates", params);
export const UpdateSegmentGateData = (params, id) =>
  Put(`api/admin/segments/gates/${id}`, params);
export const DeleteSegmentGateData = (id) =>
  Delete(`api/admin/segments/gates/${id}`);

// Reviews
export const GetReviews = (params) => Get("api/admin/reviews", params);
export const UpdateStatusReview = (params, id) =>
  Put(`api/admin/reviews/${id}`, params);

// Faciliities
export const GetFacilitiesData = (params) => Get("api/admin/facility", params);
export const GetFacilitiesLists = (params) =>
  Get("api/admin/facility/lists", params);
export const StoreFacilitiesData = (params) =>
  Post("api/admin/facility", params);

export const UpdateFacilitiesData = (params, id) =>
  Post(`api/admin/facility/${id}`, params);
export const DeleteFacilitiesData = (id) => Delete(`api/admin/facility/${id}`);
