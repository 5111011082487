import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useState } from "react";
import { StoreSegmentGateData } from "../../../../config/services";
import DialogTitle from "../../../components/Dialog/DialogTitle";
import MySnackbar from "../../../components/Snackbar";
import TextField from "../../../components/TextField";

const initalState = {
  name: "",
  description: "",
  segment_id: "",
};

function Create({
  createDialogOpen,
  setCreateDialogOpen,
  fetchGerbangToll,
  segments,
}) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [input, setInput] = useState(initalState);
  const [snackbar, setSnackbar] = useState({});

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await StoreSegmentGateData(input);
      fetchGerbangToll();
      setSnackbar({
        open: true,
        message: response.data.info,
        variant: "success",
      });
      setInput(initalState);
      setCreateDialogOpen(false);
    } catch (error) {
      console.log(error);
      setErrors(error.response.data);
      setSnackbar({
        open: true,
        message: error.response.data.message,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setCreateDialogOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({});
  };

  return (
    <>
      <MySnackbar
        open={snackbar?.open}
        onClose={handleCloseSnackbar}
        message={snackbar?.message || ""}
        variant={snackbar.variant || "info"}
      />
      <Dialog fullWidth maxWidth="sm" open={createDialogOpen} scroll="body">
        <DialogTitle>Buat Gerbang Toll</DialogTitle>
        <DialogContent>
          <div
            style={{
              paddingTop: 20,
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <TextField
                  fullWidth
                  label="Nama Gerbang"
                  name="name"
                  type="text"
                  value={input.name ?? ""}
                  error={errors?.errors?.name}
                  helperText={errors?.errors?.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Deskripsi"
                  name="description"
                  type="text"
                  value={input.description ?? ""}
                  error={errors?.errors?.description}
                  helperText={errors?.errors?.description}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <FormControl error={errors?.errors?.segment_id} fullWidth>
                  <InputLabel>Gerbang Toll</InputLabel>
                  <Select
                    fullWidth
                    label="Gerbang Toll"
                    name="segment_id"
                    value={input.segment_id}
                    error={errors?.errors?.segment_id}
                    onChange={handleChange}
                    placeholder="Pilih Gerbang Toll"
                  >
                    {segments.map((segment) => (
                      <MenuItem value={segment.id}>{segment.name}</MenuItem>
                    ))}
                  </Select>
                  {errors?.errors?.segment_id && (
                    <FormHelperText>
                      {errors?.errors?.segment_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            variant="contained"
            style={{
              width: 120,
            }}
            disabled={loading}
            color="primary"
          >
            {loading ? (
              <CircularProgress size={25} color="secondary" />
            ) : (
              `Create`
            )}
          </Button>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Create;
